@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  position: fixed; /* Make the scrollbar fixed */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #61dafb;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide scrollbar for Firefox, IE, and Edge */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .scrollbar-hide::-webkit-scrollbar {
    display: none;  /* Chrome, Safari and Opera */
  }

  /* Toggle Switch Styles */
#toggle {
  display: none;
}

#toggle + label {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: 48px;
  border-radius: 9999px;
  position: relative;
}

#toggle + label div {
  height: 16px;
  width: 16px;
  border-radius: 9999px;
  transition: transform 0.3s;
}

#toggle:checked + label div {
  transform: translateX(24px);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.form-checkbox {
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #52d4f2;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.form-checkbox:checked {
  background-color: #2fea9b;
  border-color: #2fea9b;
}

.form-checkbox:checked::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #0b3954;
  font-size: 14px;
  line-height: 16px;
}

/* Customize the appearance of the volume slider */
input[type="range"] {
  -webkit-appearance: none;
  background: rgba(82, 212, 242, 0.3);
  border-radius: 5px;
  height: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #2feA9B;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #2feA9B;
  border-radius: 50%;
  cursor: pointer;
}

.lyrics-container {
  z-index: 10;
  position: relative;
}

.scrolling-text {
  white-space: nowrap;
  position: relative;
  transition: all 0.2s ease;
}

.scrolling-text:hover {
  animation: scrollText 8s linear infinite;
  cursor: pointer;
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  45% {
    transform: translateX(calc(-100% + 15rem));
  }
  55% {
    transform: translateX(calc(-100% + 15rem));
  }
  100% {
    transform: translateX(0%);
  }
}

.text-container {
  max-width: 100%;
  overflow: hidden;
}