/* src/styles/northernLights.css */
.northern-lights {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  background-color: #001324;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.star {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  opacity: 0.5;
  animation: twinkle linear infinite;
}

@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 0.7; }
}

.wave-container {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  mix-blend-mode: screen;
}

.wave {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  vector-effect: non-scaling-stroke;
}

.content-wrapper {
  position: relative;
  z-index: 1;
  background-color: rgba(0, 19, 36, 0.5);
}

@media (max-width: 768px) {
  .wave-container {
    opacity: 0.7;
  }
}