.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Form Input Styles */
input[type="text"], input[type="email"], input[type="password"], select {
  background-color: #ffffff;
  color: #001324;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, select:focus {
  outline: none;
  border-color: #61dafb;
}

/* Button Styles */
button {
  background-color: #2feA9B;
  color: #001324;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

button:hover {
  background-color: #52D4F2;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
                    linear-gradient(135deg, white 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
                       calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px,
                   5px 5px;
  background-repeat: no-repeat;
}

select:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
                    linear-gradient(135deg, transparent 50%, white 50%);
  background-position: calc(100% - 15px) 1em,
                       calc(100% - 20px) 1em;
  background-size: 5px 5px,
                   5px 5px;
  background-repeat: no-repeat;
  outline: 0;
}

.slick-slider {
  margin-bottom: 30px;
}

.slick-slide img {
  margin: auto;
}

.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
}